import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalsService {
  baseurl = "";
  nombre = "";
  url = "/api";
  url_erp = "https://erp.erus.mx/api";
  fullurl = "localhost:4200";
  type = 1;
  setpassword = 0;
  public logged = 0;
  private _listners = new Subject<any>();

  constructor() {}

  listen(): Observable<any> {
    return this._listners.asObservable();
  }
  changeTab(tab) {
    this._listners.next(tab);
  }
}
