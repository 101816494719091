import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalsService } from '../../services/globals.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  mensaje = "";
  submitted = false;

  user: any = {
    email: "",
    contrasena: ""
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public globals: GlobalsService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
/*
    this.http.post<any>(`${this.globals.url}/categorias/add_imagen.php`, formData).subscribe(data => {
      console.log(data);
    });
*/
onSubmit() {
  this.submitted = true;
  if (this.form.valid) {
  this.http.post<any>(`${this.globals.url}/global/validarLogin.php`, {email: this.form.value.email, password: this.form.value.password}).subscribe(data => {
    console.log(data);
      if(data.success == true) {
        this.globals.logged = 1;
        this.globals.nombre = data.nombre;
        this.globals.type = data.type;
        this.globals.setpassword = data.setpassword;
        localStorage.setItem("moduloSeleccionado", "");
        localStorage.setItem("temaSeleccionado", "");
        localStorage.setItem("currentContenido", "0");
        if(data.type == 0) {
          this.router.navigateByUrl("/dashboard")
        } else {
          this.router.navigateByUrl("/start")
        }
      }
      else
      {
        this.mensaje = data.mensaje;
      }
    });
  }
}

}
