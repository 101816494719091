import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  modulo = "";
  tema = "";

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    console.log("meep")
    this.modulo = this.route.snapshot.paramMap.get('modulo');
    this.tema = this.route.snapshot.paramMap.get('tema');
    this.router.navigate(['/start', { modulo: this.modulo, tema: this.tema }]);
  }

}
