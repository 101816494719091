import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { GlobalsService } from "src/app/services/globals.service";
import { HttpClient } from "@angular/common/http";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Ng2SearchPipe } from "ng2-search-filter";
declare var $: any;
import swal from "sweetalert2";
import { FilterbymoduloPipe } from "src/app/pipes/filterbymodulo.pipe";

@Component({
  selector: "app-panel",
  templateUrl: "./panel.component.html",
  styleUrls: ["./panel.component.scss"],
})
export class PanelComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    public globals: GlobalsService,
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {}
  fileLoaded = true;
  message = "";
  selectedItem: any = {};
  editing = 0;
  moduloSeleccionado: any = {};
  temaSeleccionado: any = {};
  agregandoDiapositiva = false;
  imgHeight = 0;
  imgWidth = 0;
  buscarVideo = "";
  url = "";
  sendTo = "";

  currentCord = 1;
  x1 = -1;
  y1 = -1;
  x2 = -1;
  y2 = -1;
  @ViewChild("dia", { static: false }) logo: ElementRef;
  @ViewChild("canvas", { static: false }) public canvas: ElementRef;

  public context: CanvasRenderingContext2D;

  loaderModulos = false;
  buscarModulo = "";
  moduloForm: FormGroup;
  modulos: any = [];
  calificaciones: any = [];
  submittedModulo = false;
  modulo: any = {
    nombre: "",
    color: "#FFFFFF",
    tiempo: 1,
    insignia: "",
    email: "",
  };

  buscarTema = "";
  temaForm: FormGroup;
  temas: any = [];
  submittedTema = false;
  tema: any = {
    nombre: "",
    descripcion: "#FFFFFF",
  };

  buscarUsuario = "";
  usuarioForm: FormGroup;
  usuarios: any = [];
  submittedUsuario = false;
  usuario: any = {
    email: "",
    password: "erus",
    nombre: "",
    type: 1,
    sucursal_id: 1,
  };

  loaderPreguntas = false;
  buscarPregunta = "";
  preguntaForm: FormGroup;
  preguntas: any = [];
  submittedPregunta = false;
  pregunta: any = {
    pregunta: "",
    respuesta1: "",
    respuesta2: "",
    respuesta3: "",
    respuesta4: "",
    respuesta_correcta: 1,
  };

  loaderPreguntasFinal = false;
  buscarPreguntaFinal = "";
  preguntaFinalForm: FormGroup;
  preguntasFinal: any = [];
  submittedPreguntaFinal = false;
  preguntaFinal: any = {
    pregunta: "",
    respuesta1: "",
    respuesta2: "",
    respuesta3: "",
    respuesta4: "",
    respuesta_correcta: 1,
  };

  loaderPresentaciones = false;
  buscarPresentacion = "";
  presentacionForm: FormGroup;
  presentaciones: any = [];
  submittedPresentacion = false;
  presentacion: any = {
    nombre: "",
  };
  presentacionSeleccionada: any = {};

  loaderDefiniciones = false;
  buscarDefinicion = "";
  definicionForm: FormGroup;
  definiciones: any = [];
  submittedDefinicion = false;
  definicion: any = {
    nombre: "",
    definicion: "",
  };
  definicionSeleccionada: any = {};

  loaderMeetings = false;
  buscarMeeting = "";
  meetingForm: FormGroup;
  meetings: any = [];
  submittedMeeting = false;
  meeting: any = {
    nombre: "",
    definicion: "",
  };
  meetingSeleccionada: any = {};

  loaderDiapositivas = false;
  buscarDiapositiva = "";
  diapositivas: any = [];
  submittedDiapositiva = false;
  diapositiva: any = {
    presentacion_id: 0,
    image: "",
    coords: "",
  };

  loaderContenido = false;
  buscarContenido = "";
  contenidoForm: FormGroup;
  contenidos: any = [];
  submittedContenido = false;
  contenido: any = {
    tipo: "",
    texto: "",
  };

  sucursales: any = [];
  videos: any = [];
  interactivos: any = [];
  file_name = "";
  fileToUpload: File = null;
  instrucciones = "";

  ngOnInit() {
    $("#summernote").summernote({
      placeholder: "Ingresa texto...",
      tabsize: 2,
      height: 300,
    });

    this.listarSucursales();
    this.getInstrucciones();
    this.listarMeetings();
    this.listarModulos();
    this.listarTemas();
    this.listarVideos();
    this.listarUsuarios();
    //this.listarInteractivos();
    this.listarPresentaciones();

    this.listarDefiniciones();

    this.moduloForm = this.formBuilder.group(
      {
        nombre: ["", Validators.required],
        color: [""],
        tiempo: ["", Validators.required],
        email: ["", Validators.required],
      },
      {}
    );
    this.temaForm = this.formBuilder.group(
      {
        nombre: ["", Validators.required],
        descripcion: ["", Validators.required],
      },
      {}
    );
    this.preguntaForm = this.formBuilder.group(
      {
        pregunta: ["", Validators.required],
        respuesta1: ["", Validators.required],
        respuesta2: ["", Validators.required],
        respuesta3: ["", Validators.required],
        respuesta4: ["", Validators.required],
        respuesta_correcta: [1, Validators.required],
      },
      {}
    );
    this.preguntaFinalForm = this.formBuilder.group(
      {
        pregunta: ["", Validators.required],
        respuesta1: ["", Validators.required],
        respuesta2: ["", Validators.required],
        respuesta3: ["", Validators.required],
        respuesta4: ["", Validators.required],
        respuesta_correcta: [1, Validators.required],
      },
      {}
    );
    this.contenidoForm = this.formBuilder.group(
      {
        tipo: [1, Validators.required],
        texto: ["", Validators.required],
      },
      {}
    );
    this.presentacionForm = this.formBuilder.group(
      {
        nombre: ["", Validators.required],
      },
      {}
    );
    this.definicionForm = this.formBuilder.group(
      {
        nombre: ["", Validators.required],
        definicion: ["", Validators.required],
      },
      {}
    );
    this.usuarioForm = this.formBuilder.group(
      {
        email: ["", Validators.required],
        password: ["erus", Validators.required],
        nombre: ["", Validators.required],
        sucursal_id: [1, Validators.required],
        type: [1],
      },
      {}
    );
    this.meetingForm = this.formBuilder.group(
      {
        topic: ["", Validators.required],
        start_time: ["", Validators.required],
        duration: [1, Validators.required],
        password: [""],
        description: ["", Validators.required],
      },
      {}
    );
  }

  handleFileInput(files) {
    this.fileToUpload = files.item(0);
    this.file_name = this.fileToUpload.name;
    this.file_name = this.file_name.toLowerCase();

    const formData: FormData = new FormData();
    formData.append("file", this.fileToUpload, this.file_name);
    formData.append("file_name", this.file_name);

    this.http
      .post<any>(`${this.globals.url}/modulos/add_imagen.php`, formData)
      .subscribe((data) => {
        console.log(data);
      });
  }
  getInstrucciones() {
    this.http
      .get<any>(`${this.globals.url}/global/get_instrucciones.php`)
      .subscribe((data) => {
        this.instrucciones = data.instrucciones;
        console.log(data);
        $("#summernote").summernote("code", this.instrucciones);
      });
  }
  saveInstrucciones() {
    this.instrucciones = $("#summernote").summernote("code");
    this.http
      .post<any>(`${this.globals.url}/global/set_instrucciones.php`, {
        instrucciones: this.instrucciones,
      })
      .subscribe((data) => {
        console.log(data);
        $("#instrucciones").modal("hide");
        swal.fire({
          title: "Se han actualizado las instrucciones.",
          text: "",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "OK",
        });
        //$('#summernote').summernote('code', this.instrucciones);
      });
  }
  listarVideos() {
    this.http
      .get<any>(`${this.globals.url}/temas/tutoriales/videos_list.php`)
      .subscribe((data) => {
        this.videos = data;
        console.log(data);
      });
  }
  listarInteractivos() {
    this.http
      .get<any>(`${this.globals.url}/temas/tutoriales/interactivos_list.php`)
      .subscribe((data) => {
        this.interactivos = data;
        console.log(data);
      });
  }
  async listarUsuarios() {
    this.http
      .get<any>(`${this.globals.url}/users/list.php`)
      .subscribe((data) => {
        this.http
          .get<any>(`${this.globals.url_erp}/catalogos/sucursales/list.php`)
          .subscribe((x) => {
            var sucursalesList = x.response;

            data.forEach((element) => {
              let sucursal = sucursalesList.find(
                (x) => Number(x.id) === Number(element.sucursal_id)
              );

              if (sucursal) {
                element.nombre_sucursal = sucursal.nombre;
              }
            });

            this.usuarios = data;
          });
      });
  }
  listarSucursales() {
    this.http
      .get<any>(`${this.globals.url_erp}/catalogos/sucursales/list.php`)
      .subscribe((data) => {
        this.sucursales = data.response;
        return data.response;
        // console.log(data);
      });
  }

  // getSucursal(id) {

  //   let foundUser = this.sucursales.find((user) => user.id === id);
  //   console.log("aAAAAAAA", foundUser);

  //   // return users.users[key]
  // }
  moveUp(m) {
    if (this.loaderModulos == true) return;
    this.http
      .post<any>(`${this.globals.url}/modulos/moveUp.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarModulos();
        console.log(data);
      });
  }
  moveDown(m) {
    if (this.loaderModulos == true) return;
    this.http
      .post<any>(`${this.globals.url}/modulos/moveDown.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarModulos();
        console.log(data);
      });
  }
  moveUpPregunta(m) {
    if (this.loaderPreguntas == true) return;
    this.http
      .post<any>(`${this.globals.url}/preguntas/moveUp.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarPreguntas();
        console.log(data);
      });
  }
  moveDownPregunta(m) {
    if (this.loaderPreguntas == true) return;
    this.http
      .post<any>(`${this.globals.url}/preguntas/moveDown.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarPreguntas();
        console.log(data);
      });
  }
  moveUpPreguntaFinal(m) {
    if (this.loaderPreguntas == true) return;
    this.http
      .post<any>(`${this.globals.url}/preguntas_final/moveUp.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarPreguntasFinal();
        console.log(data);
      });
  }
  moveDownPreguntaFinal(m) {
    if (this.loaderPreguntas == true) return;
    this.http
      .post<any>(`${this.globals.url}/preguntas_final/moveDown.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarPreguntasFinal();
        console.log(data);
      });
  }
  moveUpTema(m) {
    if (this.loaderPreguntas == true) return;
    this.http
      .post<any>(`${this.globals.url}/temas/moveUp.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarTemas();
        console.log(data);
      });
  }
  moveDownTema(m) {
    if (this.loaderPreguntas == true) return;
    this.http
      .post<any>(`${this.globals.url}/temas/moveDown.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarTemas();
        console.log(data);
      });
  }
  moveUpDiapositiva(m) {
    if (this.loaderDiapositivas == true) return;
    this.http
      .post<any>(`${this.globals.url}/diapositivas/moveUp.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarDiapositivas();
        console.log(data);
      });
  }
  moveDownDiapositiva(m) {
    if (this.loaderDiapositivas == true) return;
    this.http
      .post<any>(`${this.globals.url}/diapositivas/moveDown.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarDiapositivas();
        console.log(data);
      });
  }
  moveUpContenido(m) {
    if (this.loaderContenido == true) return;
    this.http
      .post<any>(`${this.globals.url}/contenido/moveUp.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarContenidos();
        console.log(data);
      });
  }
  moveDownContenido(m) {
    if (this.loaderContenido == true) return;
    this.http
      .post<any>(`${this.globals.url}/contenido/moveDown.php`, {
        id: m.id,
        orden: m.orden,
      })
      .subscribe((data) => {
        this.listarContenidos();
        console.log(data);
      });
  }
  changeModulo(m) {
    this.moduloSeleccionado = m;
  }
  listarModulos() {
    this.loaderModulos = true;
    this.http
      .get<any>(`${this.globals.url}/modulos/list.php`)
      .subscribe((data) => {
        this.modulos = data;
        this.moduloSeleccionado = data[0];
        console.log(data);
        this.loaderModulos = false;
      });
  }
  listarTemas() {
    this.http
      .get<any>(`${this.globals.url}/temas/list.php`)
      .subscribe((data) => {
        this.temas = data;
        console.log(data);
      });
  }
  getTemas() {
    var filterPipe = new Ng2SearchPipe();
    var filterbymoduloPipe = new FilterbymoduloPipe();
    var filteredArray = filterPipe.transform(this.temas, this.buscarTema);
    filteredArray = filterbymoduloPipe.transform(
      filteredArray,
      this.moduloSeleccionado.id
    );
    return filteredArray.length;
  }
  getPresentaciones() {
    var filterPipe = new Ng2SearchPipe();
    var filteredArray = filterPipe.transform(
      this.presentaciones,
      this.buscarPresentacion
    );
    return filteredArray.length;
  }
  getUsuarios() {
    var filterPipe = new Ng2SearchPipe();
    var filteredArray = filterPipe.transform(this.usuarios, this.buscarUsuario);
    return filteredArray.length;
  }
  getDefiniciones() {
    var filterPipe = new Ng2SearchPipe();
    var filteredArray = filterPipe.transform(
      this.definiciones,
      this.buscarDefinicion
    );
    return filteredArray.length;
  }
  getMeetings() {
    var filterPipe = new Ng2SearchPipe();
    var filteredArray = filterPipe.transform(this.meetings, this.buscarMeeting);
    return filteredArray.length;
  }
  getModulos() {
    var filterPipe = new Ng2SearchPipe();
    var filteredArray = filterPipe.transform(this.modulos, this.buscarModulo);
    return filteredArray.length;
  }
  addModulo() {
    this.submittedModulo = true;
    // stop here if form is invalid
    if (this.moduloForm.invalid) {
      console.log("invalid");
      return;
    }
    const color = this.modulo.color;
    this.modulo = this.moduloForm.value;
    this.modulo.color = color;
    console.log(this.modulo);
    this.modulo.insignia = this.file_name;
    this.http
      .post<any>(`${this.globals.url}/modulos/add.php`, this.modulo)
      .subscribe((data) => {
        console.log(data);
        this.onResetModulo();
        this.listarModulos();
        $("#addModulo").modal("hide");
      });
  }
  editModulo() {
    this.submittedModulo = true;
    console.log(this.moduloForm.value);
    // stop here if form is invalid
    if (this.moduloForm.invalid) {
      console.log("invalid");
      return;
    }
    const color = this.modulo.color;
    const idd = this.modulo.id;
    const insigniaa = this.modulo.insignia;
    this.modulo = this.moduloForm.value;
    this.modulo.id = idd;
    if (this.moduloForm.value.insignia == "")
      this.moduloForm.value.insignia = insigniaa;
    if (this.file_name != "") this.modulo.insignia = this.file_name;
    else this.modulo.insignia = insigniaa;
    this.modulo.color = color;
    this.http
      .post<any>(`${this.globals.url}/modulos/edit.php`, this.modulo)
      .subscribe((data) => {
        console.log(data);
        this.onResetModulo();
        this.listarModulos();

        $("#editModulo").modal("hide");
      });
  }
  deleteModulo(id) {
    $("#deleteModulo").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/modulos/delete.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarModulos();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  deleteVideo(video) {
    $("#deleteVideo").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/temas/tutoriales/delete_video.php`, {
        video: video,
      })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarVideos();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  showAddModulo() {
    this.file_name = "";
    this.onResetModulo();
    this.editing = 0;
    $("#addModulo").modal("show");
  }
  showEditInstrucciones() {
    $("#instrucciones").modal("show");
  }
  showEditModulo(id) {
    this.http
      .get<any>(`${this.globals.url}/modulos/delete_temp.php`)
      .subscribe((data) => {
        console.log(data);
      });

    this.editing = 1;
    this.http
      .post<any>(`${this.globals.url}/modulos/get.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        this.modulo = data;
        this.file_name = this.modulo.insignia;
        this.moduloForm.controls["nombre"].setValue(this.modulo.nombre);
        this.moduloForm.controls["color"].setValue(this.modulo.color);
        this.moduloForm.controls["tiempo"].setValue(this.modulo.tiempo);
        this.moduloForm.controls["email"].setValue(this.modulo.email);
      });

    $("#editModulo").modal("show");
  }
  showDeleteModulo(a) {
    this.selectedItem = a;
    $("#deleteModulo").modal("show");
  }
  showDeleteVideo(v) {
    this.selectedItem = v;
    $("#deleteVideo").modal("show");
  }
  showInsignia(m) {
    this.selectedItem = m;
    $("#verInsignia").modal("show");
  }
  onResetModulo() {
    this.modulo = {
      nombre: "",
      color: "#FFFFFF",
      tiempo: 1,
      insignia: "",
    };
    this.submittedModulo = false;
    this.moduloForm.reset();
  }
  get fm() {
    return this.moduloForm.controls;
  }

  showExamen(m) {
    this.temaSeleccionado = m;
    this.listarPreguntas();
    $("#examen").modal("show");
  }
  showExamenFinal(m) {
    this.moduloSeleccionado = m;
    this.listarPreguntasFinal();
    $("#examenFinal").modal("show");
  }
  showContenido(t) {
    this.temaSeleccionado = t;
    this.listarContenidos();
    $("#contenido").modal("show");
  }
  listarPreguntas() {
    this.loaderPreguntas = true;
    this.http
      .post<any>(`${this.globals.url}/preguntas/list.php`, {
        tema_id: this.temaSeleccionado.id,
      })
      .subscribe((data) => {
        console.log(data);
        this.loaderPreguntas = false;
        this.preguntas = data;
      });
  }
  listarPreguntasFinal() {
    this.loaderPreguntasFinal = true;
    this.http
      .post<any>(`${this.globals.url}/preguntas_final/list.php`, {
        modulo_id: this.moduloSeleccionado.id,
      })
      .subscribe((data) => {
        console.log(data);
        this.loaderPreguntasFinal = false;
        this.preguntasFinal = data;
      });
  }
  listarContenidos() {
    this.loaderModulos = true;
    this.http
      .post<any>(`${this.globals.url}/contenido/list.php`, {
        tema_id: this.temaSeleccionado.id,
      })
      .subscribe((data) => {
        console.log(data);
        this.loaderContenido = false;
        this.contenidos = data;
      });
  }
  addTema() {
    this.submittedTema = true;
    // stop here if form is invalid
    if (this.temaForm.invalid) {
      console.log("invalid");
      return;
    }
    this.tema = this.temaForm.value;
    if (
      this.moduloSeleccionado.id == null ||
      this.moduloSeleccionado.id == undefined
    ) {
      swal.fire({
        title: "Selecciona un módulo.",
        text: "",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
    } else {
      this.tema.modulo_id = this.moduloSeleccionado.id;
      console.log(this.tema);
      this.http
        .post<any>(`${this.globals.url}/temas/add.php`, this.tema)
        .subscribe((data) => {
          console.log(data);
          this.onResetTema();
          this.listarTemas();
          $("#addTema").modal("hide");
        });
    }
  }
  editTema() {
    this.submittedTema = true;
    console.log(this.temaForm.value);
    // stop here if form is invalid
    if (this.temaForm.invalid) {
      console.log("invalid");
      return;
    }
    const idd = this.tema.id;
    const modulo_id = this.tema.modulo_id;
    this.tema = this.temaForm.value;
    this.tema.id = idd;
    this.tema.modulo_id = modulo_id;
    this.http
      .post<any>(`${this.globals.url}/temas/edit.php`, this.tema)
      .subscribe((data) => {
        console.log(data);
        this.onResetTema();
        this.listarTemas();

        $("#editTema").modal("hide");
      });
  }
  deleteTema(id) {
    $("#deleteTema").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/temas/delete.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarTemas();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  showAddTema() {
    this.onResetTema();
    this.editing = 0;
    $("#addTema").modal("show");
  }
  showEditTema(id) {
    this.editing = 1;
    this.http
      .post<any>(`${this.globals.url}/temas/get.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        this.tema = data;
        this.temaForm.controls["nombre"].setValue(this.tema.nombre);
        this.temaForm.controls["descripcion"].setValue(this.tema.descripcion);
      });

    $("#editTema").modal("show");
  }
  showDeleteTema(a) {
    this.selectedItem = a;
    $("#deleteTema").modal("show");
  }
  onResetTema() {
    this.tema = {
      nombre: "",
      color: "#FFFFFF",
      tiempo: 1,
    };
    this.submittedTema = false;
    this.temaForm.reset();
  }
  get ft() {
    return this.temaForm.controls;
  }

  addPregunta() {
    this.submittedPregunta = true;
    // stop here if form is invalid
    if (this.preguntaForm.invalid) {
      console.log("invalid");
      return;
    }
    this.pregunta = this.preguntaForm.value;
    this.pregunta.tema_id = this.temaSeleccionado.id;
    console.log(this.pregunta);
    this.http
      .post<any>(`${this.globals.url}/preguntas/add.php`, this.pregunta)
      .subscribe((data) => {
        console.log(data);
        this.onResetPregunta();
        this.listarPreguntas();
        $("#addPregunta").modal("hide");
      });
  }
  editPregunta() {
    this.submittedPregunta = true;
    console.log(this.preguntaForm.value);
    // stop here if form is invalid
    if (this.preguntaForm.invalid) {
      console.log("invalid");
      return;
    }
    const idd = this.pregunta.id;
    const tema_id = this.pregunta.tema_id;
    this.pregunta = this.preguntaForm.value;
    this.pregunta.id = idd;
    this.pregunta.tema_id = tema_id;
    this.http
      .post<any>(`${this.globals.url}/preguntas/edit.php`, this.pregunta)
      .subscribe((data) => {
        console.log(data);
        this.onResetPregunta();
        this.listarPreguntas();

        $("#editPregunta").modal("hide");
      });
  }
  deletePregunta(id) {
    $("#deletePregunta").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/preguntas/delete.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarPreguntas();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  showAddPregunta() {
    this.onResetPregunta();
    this.preguntaForm.controls["respuesta_correcta"].setValue(1);
    this.editing = 0;
    $("#addPregunta").modal("show");
  }
  showEditPregunta(id) {
    this.editing = 1;
    this.http
      .post<any>(`${this.globals.url}/preguntas/get.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        this.pregunta = data;
        this.preguntaForm.controls["pregunta"].setValue(this.pregunta.pregunta);
        this.preguntaForm.controls["respuesta1"].setValue(
          this.pregunta.respuesta1
        );
        this.preguntaForm.controls["respuesta2"].setValue(
          this.pregunta.respuesta2
        );
        this.preguntaForm.controls["respuesta3"].setValue(
          this.pregunta.respuesta3
        );
        this.preguntaForm.controls["respuesta4"].setValue(
          this.pregunta.respuesta4
        );
        this.preguntaForm.controls["respuesta_correcta"].setValue(
          this.pregunta.respuesta_correcta
        );
      });

    $("#editPregunta").modal("show");
  }
  showDeletePregunta(a) {
    this.selectedItem = a;
    $("#deletePregunta").modal("show");
  }
  onResetPregunta() {
    this.pregunta = {
      pregunta: "",
      respuesta1: "",
      respuesta2: "",
      respuesta3: "",
      respuesta4: "",
      respuesta_correcta: 1,
    };
    this.submittedPregunta = false;
    this.preguntaForm.reset();
  }
  get fp() {
    return this.preguntaForm.controls;
  }

  addContenido() {
    this.submittedContenido = true;
    // stop here if form is invalid
    if (this.contenidoForm.invalid) {
      console.log("invalid");
      return;
    }
    this.contenido = this.contenidoForm.value;
    this.contenido.tema_id = this.temaSeleccionado.id;
    console.log(this.contenido);
    this.http
      .post<any>(`${this.globals.url}/contenido/add.php`, this.contenido)
      .subscribe((data) => {
        console.log(data);
        this.onResetContenido();
        this.listarContenidos();
        $("#addContenido").modal("hide");
      });
  }
  editContenido() {
    this.submittedContenido = true;
    console.log(this.contenidoForm.value);
    // stop here if form is invalid
    if (this.contenidoForm.invalid) {
      console.log("invalid");
      return;
    }
    const idd = this.contenido.id;
    const tema_id = this.contenido.tema_id;
    this.contenido = this.contenidoForm.value;
    this.contenido.id = idd;
    this.contenido.tema_id = tema_id;
    this.http
      .post<any>(`${this.globals.url}/contenido/edit.php`, this.contenido)
      .subscribe((data) => {
        console.log(data);
        this.onResetContenido();
        this.listarContenidos();

        $("#editContenido").modal("hide");
      });
  }
  deleteContenido(id) {
    $("#deleteContenido").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/contenido/delete.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarContenidos();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  setTexto() {
    this.contenidoForm.controls["texto"].setValue("");
  }
  showAddContenido() {
    this.onResetContenido();
    this.editing = 0;
    this.contenidoForm.controls["tipo"].setValue(1);
    this.contenidoForm.controls["texto"].setValue("");
    $("#addContenido").modal("show");
  }
  showEditContenido(id) {
    this.editing = 1;
    this.http
      .post<any>(`${this.globals.url}/contenido/get.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        this.contenido = data;
        this.contenidoForm.controls["tipo"].setValue(this.contenido.tipo);
        this.contenidoForm.controls["texto"].setValue(this.contenido.texto);
      });

    $("#editContenido").modal("show");
  }
  showDeleteContenido(a) {
    this.selectedItem = a;
    $("#deleteContenido").modal("show");
  }
  onResetContenido() {
    this.contenido = {
      tipo: 1,
      texto: "",
    };
    this.submittedContenido = false;
    this.contenidoForm.reset();
  }
  get fc() {
    return this.contenidoForm.controls;
  }

  listarPresentaciones() {
    this.loaderPresentaciones = true;
    this.http
      .get<any>(`${this.globals.url}/presentaciones/list.php`)
      .subscribe((data) => {
        console.log(data);
        this.loaderPresentaciones = false;
        this.presentaciones = data;
      });
  }
  listarDefiniciones() {
    this.loaderDefiniciones = true;
    this.http
      .get<any>(`${this.globals.url}/definiciones/list.php`)
      .subscribe((data) => {
        console.log(data);
        this.loaderDefiniciones = false;
        this.definiciones = data;
      });
  }
  listarMeetings() {
    this.loaderMeetings = true;
    this.http
      .get<any>(`${this.globals.url}/zoom/list_meetings.php`)
      .subscribe((data) => {
        console.log(data);
        this.loaderMeetings = false;
        this.meetings = data.response.meetings;

        for (var i = 0; i < this.meetings.length; i++) {
          var tmpDate = new Date(this.meetings[i].start_time);

          this.meetings[i].date = this.format(tmpDate);
          this.meetings[i].time = this.tConvert(this.timeNow(tmpDate));
        }
      });
  }
  tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  timeNow(date) {
    var h = (date.getHours() < 10 ? "0" : "") + date.getHours();
    var m = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    return h + ":" + m;
  }
  format(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  }
  addPresentacion() {
    this.submittedPresentacion = true;
    // stop here if form is invalid
    if (this.presentacionForm.invalid) {
      console.log("invalid");
      return;
    }
    this.presentacion = this.presentacionForm.value;
    console.log(this.presentacion);
    this.http
      .post<any>(
        `${this.globals.url}/presentaciones/add.php`,
        this.presentacion
      )
      .subscribe((data) => {
        console.log(data);
        this.onResetPresentacion();
        this.listarPresentaciones();
        $("#addPresentacion").modal("hide");
      });
  }
  editPresentacion() {
    this.submittedPresentacion = true;
    console.log(this.presentacionForm.value);
    // stop here if form is invalid
    if (this.presentacionForm.invalid) {
      console.log("invalid");
      return;
    }

    const idd = this.presentacion.id;
    //const rutaa = this.modulo.ruta;
    this.presentacion = this.presentacionForm.value;
    this.presentacion.id = idd;
    //if(this.presentacionForm.value.ruta == "")this.presentacionForm.value.ruta = rutaa;
    //if(this.file_name != "")this.presentacion.ruta = this.file_name; else this.presentacion.ruta = rutaa;
    this.http
      .post<any>(
        `${this.globals.url}/presentaciones/edit.php`,
        this.presentacion
      )
      .subscribe((data) => {
        console.log(data);
        this.onResetPresentacion();
        this.listarPresentaciones();

        $("#editPresentacion").modal("hide");
      });
  }
  deletePresentacion(id) {
    $("#deletePresentacion").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/presentaciones/delete.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarPresentaciones();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  showAddPresentacion() {
    this.file_name = "";
    this.onResetPresentacion();
    this.editing = 0;
    $("#addPresentacion").modal("show");
  }
  showEditPresentacion(id) {
    this.editing = 1;
    this.http
      .post<any>(`${this.globals.url}/presentaciones/get.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        this.presentacion = data;
        //this.file_name = this.presentacion.ruta;
        this.presentacionForm.controls["nombre"].setValue(
          this.presentacion.nombre
        );
      });

    $("#editPresentacion").modal("show");
  }
  showDeletePresentacion(a) {
    this.selectedItem = a;
    $("#deletePresentacion").modal("show");
  }
  onResetPresentacion() {
    this.presentacion = {
      nombre: "",
      color: "#FFFFFF",
      tiempo: 1,
    };
    this.submittedPresentacion = false;
    this.presentacionForm.reset();
  }
  get fpp() {
    return this.presentacionForm.controls;
  }

  showDiapositivas(m) {
    this.presentacionSeleccionada = m;
    this.listarDiapositivas();
    $("#diapositiva").modal("show");
  }

  listarDiapositivas() {
    this.loaderDiapositivas = true;
    this.http
      .post<any>(`${this.globals.url}/diapositivas/list.php`, {
        presentacion_id: this.presentacionSeleccionada.id,
      })
      .subscribe((data) => {
        console.log(data);
        this.loaderDiapositivas = false;
        this.diapositivas = data;
      });
  }
  addDiapositiva() {
    this.diapositiva.image = this.file_name;
    console.log(this.diapositiva);

    if (this.diapositiva.image == "") {
      swal.fire({
        title: "Debe seleccionar una imagen.",
        text: "",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      return;
    }
    if (this.x1 == -1 || this.y1 == -1) {
      swal.fire({
        title: "Debe agregar un recuadro para avanzar de diapositiva.",
        text: "Selecciona dando clicks en la imagen que subiste para seleccionar el recuadro al que se la va a hacer click para avanzar de diapositiva.",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      return;
    }
    if (this.x1 > this.x2) {
      var xx = this.x1;
      this.x1 = this.x2;
      this.x2 = xx;
    }
    if (this.y1 > this.y2) {
      var yy = this.y1;
      this.y1 = this.y2;
      this.y2 = yy;
    }
    this.diapositiva.coords =
      this.x1 + "," + this.y1 + "," + this.x2 + "," + this.y2;
    if (this.diapositiva.image == "") {
      swal.fire({
        title: "Debe seleccionar una imagen.",
        text: "",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      return;
    }
    this.http
      .post<any>(`${this.globals.url}/diapositivas/add.php`, this.diapositiva)
      .subscribe((data) => {
        console.log(data);
        this.onResetDiapositiva();
        this.listarDiapositivas();
        this.regresar();
      });
  }

  editDiapositiva() {
    if (this.file_name != "") this.diapositiva.image = this.file_name;
    console.log(this.diapositiva);

    if (this.diapositiva.image == "") {
      swal.fire({
        title: "Debe seleccionar una imagen.",
        text: "",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      return;
    }
    if (this.x1 == -1 || this.y1 == -1) {
      swal.fire({
        title: "Debe agregar un recuadro para avanzar de diapositiva.",
        text: "Selecciona dando clicks en la imagen que subiste para seleccionar el recuadro al que se la va a hacer click para avanzar de diapositiva.",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      return;
    }
    if (this.x1 > this.x2) {
      var xx = this.x1;
      this.x1 = this.x2;
      this.x2 = xx;
    }
    if (this.y1 > this.y2) {
      var yy = this.y1;
      this.y1 = this.y2;
      this.y2 = yy;
    }
    this.diapositiva.coords =
      this.x1 + "," + this.y1 + "," + this.x2 + "," + this.y2;
    if (this.diapositiva.image == "") {
      swal.fire({
        title: "Debe seleccionar una imagen.",
        text: "",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      return;
    }
    this.http
      .post<any>(`${this.globals.url}/diapositivas/edit.php`, this.diapositiva)
      .subscribe((data) => {
        console.log(data);
        this.onResetDiapositiva();
        this.listarDiapositivas();
        this.regresar();
      });
  }

  // editDiapositiva() {
  //   this.http.post<any>(`${this.globals.url}/diapositivas/edit.php`, this.diapositiva).subscribe(data => {
  //     console.log(data);
  //     this.onResetDiapositiva();
  //     this.listarDiapositivas();

  //     $('#editDiapositiva').modal('hide');
  //   });
  // }
  deleteDiapositiva(id) {
    $("#deleteDiapositiva").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/diapositivas/delete.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarDiapositivas();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  showAddDiapositiva() {
    this.http
      .get<any>(`${this.globals.url}/diapositivas/delete_temp.php`)
      .subscribe((data) => {
        console.log(data);
      });

    this.onResetDiapositiva();
    this.editing = 0;
    this.agregandoDiapositiva = true;
    window.scroll(0, 0);
    this.diapositiva.presentacion_id = this.presentacionSeleccionada.id;
    $("#diapositiva").modal("hide");
  }
  showEditDiapositiva(id) {
    console.log(id);
    this.fileLoaded = false;
    this.http
      .get<any>(`${this.globals.url}/diapositivas/delete_temp.php`)
      .subscribe((data) => {
        console.log(data);

        this.http
          .post<any>(`${this.globals.url}/diapositivas/get.php`, { id: id })
          .subscribe((data) => {
            console.log(data);
            this.diapositiva = data;

            this.fileLoaded = true;

            var other = this;
            var currentCord = this.currentCord;
            var x1 = this.x1;
            var y1 = this.y1;
            var x2 = this.x2;
            var y2 = this.y2;
            var other = this;
            $(document).ready(function () {
              $("#dia").click(function (e) {
                var offset_t = $(this).offset().top - $(window).scrollTop();
                var offset_l = $(this).offset().left - $(window).scrollLeft();

                var left = Math.round(e.clientX - offset_l);
                var top = Math.round(e.clientY - offset_t);

                console.log("Left: " + left + " Top: " + top);
                if (currentCord == 1) {
                  x1 = left;
                  y1 = top;
                } else {
                  x2 = left;
                  y2 = top;
                }
                if (currentCord == 1) {
                  currentCord = 2;
                  x2 = -1;
                  y2 = -1;
                } else {
                  currentCord = 1;
                }
                other.updateVars(x1, x2, y1, y2);
              });
            });

            var other = this;
            setTimeout(function () {
              var coords = other.diapositiva.coords.split(",");
              other.updateVars(
                parseInt(coords[0]),
                parseInt(coords[2]),
                parseInt(coords[1]),
                parseInt(coords[3])
              );
            }, 200);
          });
      });

    this.editing = 1;
    this.agregandoDiapositiva = true;
    window.scroll(0, 0);
    $("#diapositiva").modal("hide");
  }
  checar() {
    if (!this.editing) {
      this.addDiapositiva();
    } else this.editDiapositiva();
  }
  showDeleteDiapositiva(a) {
    this.selectedItem = a;
    $("#deleteDiapositiva").modal("show");
  }
  onResetDiapositiva() {
    this.diapositiva = {
      presentacion_id: 0,
      image: "",
      coords: "",
    };
  }
  handleFileInputDiapositiva(files) {
    this.fileToUpload = files.item(0);
    this.file_name = this.fileToUpload.name;
    this.file_name = this.file_name.replace(/\s/g, "").toLowerCase();

    /*
    var ext = this.file_name.substring(this.file_name.lastIndexOf('.')+1, this.file_name.length) || this.file_name;
    this.file_name = this.file_name.substring(0, this.file_name.lastIndexOf('.'));

    this.file_name = this.file_name.replace(/[^a-z0-9\s-]/ig,'')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase();

    this.file_name += "." + ext;
    */

    const formData: FormData = new FormData();
    formData.append("file", this.fileToUpload, this.file_name);
    formData.append("file_name", this.file_name);

    this.fileLoaded = false;
    this.http
      .post<any>(`${this.globals.url}/diapositivas/add_imagen.php`, formData)
      .subscribe((data) => {
        console.log(data);
        this.fileLoaded = true;

        var other = this;
        var currentCord = this.currentCord;
        var x1 = this.x1;
        var y1 = this.y1;
        var x2 = this.x2;
        var y2 = this.y2;
        var other = this;
        $(document).ready(function () {
          $("#dia").click(function (e) {
            var offset_t = $(this).offset().top - $(window).scrollTop();
            var offset_l = $(this).offset().left - $(window).scrollLeft();

            var left = Math.round(e.clientX - offset_l);
            var top = Math.round(e.clientY - offset_t);

            console.log("Left: " + left + " Top: " + top);
            if (currentCord == 1) {
              x1 = left;
              y1 = top;
            } else {
              x2 = left;
              y2 = top;
            }
            if (currentCord == 1) {
              currentCord = 2;
              x2 = -1;
              y2 = -1;
            } else {
              currentCord = 1;
            }
            other.updateVars(x1, x2, y1, y2);
          });
        });
      });
  }

  handleFileInputVideo(files) {
    this.fileToUpload = files.item(0);
    this.file_name = this.fileToUpload.name;
    this.file_name = this.file_name.replace(/\s/g, "").toLowerCase();

    const formData: FormData = new FormData();
    formData.append("file", this.fileToUpload, this.file_name);
    formData.append("file_name", this.file_name);

    $("#loader").modal("show");
    this.http
      .post<any>(`${this.globals.url}/temas/tutoriales/add_video.php`, formData)
      .subscribe((data) => {
        console.log(data);

        this.listarVideos();

        setTimeout(function () {
          $("#loader").modal("hide");
        }, 200);
      });
  }

  updateVars(x1, x2, y1, y2) {
    this.x1 = x1;
    this.y1 = y1;
    this.x2 = x2;
    this.y2 = y2;
    console.log(this.x1);

    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.context = canvasEl.getContext("2d");

    this.context.clearRect(0, 0, this.imgWidth, this.imgHeight);

    this.context.fillStyle = "orange";
    this.context.strokeStyle = "red";
    this.context.lineWidth = 2;

    if (x1 != -1) this.context.fillRect(x1 - 2, y1 - 2, 4, 4);
    if (x2 != -1) this.context.fillRect(x2 - 2, y2 - 2, 4, 4);
    if (x1 != -1 && x2 != -1) {
      this.context.beginPath();

      this.context.moveTo(x1, y1);
      this.context.lineTo(x2, y1);
      this.context.lineTo(x2, y2);
      this.context.lineTo(x1, y2);
      this.context.lineTo(x1, y1);

      this.context.stroke();
    }
  }
  regresar() {
    this.file_name = "";

    this.imgHeight = 0;
    this.imgWidth = 0;

    this.x1 = -1;
    this.x2 = -1;
    this.y1 = -1;
    this.y2 = -1;
    this.agregandoDiapositiva = false;
    setTimeout(function () {
      $("#diapositiva").modal("show");
    }, 200);
  }

  cerrarSesion() {}
  loadedImg() {
    this.imgWidth = (this.logo.nativeElement as HTMLImageElement).width;
    this.imgHeight = (this.logo.nativeElement as HTMLImageElement).height;
  }

  addUsuario() {
    this.submittedUsuario = true;
    // stop here if form is invalid
    if (this.usuarioForm.invalid) {
      console.log("invalid");
      return;
    }

    this.usuario = this.usuarioForm.value;
    console.log(this.usuario);
    this.usuario.type = 1;
    this.http
      .post<any>(`${this.globals.url}/users/add.php`, this.usuario)
      .subscribe((data) => {
        console.log(data);
        this.onResetUsuario();
        this.listarUsuarios();
        $("#addUsuario").modal("hide");
      });
  }
  editUsuario() {
    this.submittedUsuario = true;
    console.log(this.usuarioForm.value);
    // stop here if form is invalid
    if (this.usuarioForm.invalid) {
      console.log("invalid");
      return;
    }
    const idd = this.usuario.id;
    this.usuario = this.usuarioForm.value;
    this.usuario.id = idd;
    this.usuario.type = 1;
    this.http
      .post<any>(`${this.globals.url}/users/edit.php`, this.usuario)
      .subscribe((data) => {
        console.log(data);
        this.onResetUsuario();
        this.listarUsuarios();

        $("#editUsuario").modal("hide");
      });
  }
  deleteUsuario(id) {
    $("#deleteUsuario").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/users/delete.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarUsuarios();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  deleteProgreso(id) {
    $("#deleteProgreso").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/users/delete_progreso.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          swal.fire({
            title: "Progreso eliminado",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  showAddUsuario() {
    this.onResetUsuario();
    this.editing = 0;
    this.usuarioForm.controls["password"].setValue("erus");
    this.usuarioForm.controls["sucursal_id"].setValue(this.sucursales[0].id);
    $("#addUsuario").modal("show");
  }
  showEditUsuario(id) {
    this.editing = 1;
    this.http
      .post<any>(`${this.globals.url}/users/get.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        this.usuario = data;
        this.usuarioForm.controls["email"].setValue(this.usuario.email);
        this.usuarioForm.controls["password"].setValue(this.usuario.password);
        this.usuarioForm.controls["nombre"].setValue(this.usuario.nombre);
        this.usuarioForm.controls["type"].setValue(this.usuario.type);
        this.usuarioForm.controls["sucursal_id"].setValue(
          this.usuario.sucursal_id
        );
      });

    $("#editUsuario").modal("show");
  }
  showDeleteUsuario(a) {
    this.selectedItem = a;
    $("#deleteUsuario").modal("show");
  }
  showDeleteProgreso(a) {
    this.selectedItem = a;
    $("#deleteProgreso").modal("show");
  }
  showCalificaciones(a) {
    this.selectedItem = a;
    console.log(a);
    this.http
      .post<any>(`${this.globals.url}/users/get_calificaciones.php`, {
        id: a.id,
      })
      .subscribe((data) => {
        console.log(data);
        this.calificaciones = data;
      });

    $("#calificaciones").modal("show");
  }
  onResetUsuario() {
    this.usuario = {
      email: "",
      password: "erus",
      nombre: "",
      type: 1,
      sucursal_id: 1,
    };
    this.submittedUsuario = false;
    this.usuarioForm.reset();
  }
  get fu() {
    return this.usuarioForm.controls;
  }

  generarLink(t) {
    this.url = this.globals.fullurl + "/redirect/" + t.modulo_id + "/" + t.id;
    $("#mostrarUrl").modal("show");
  }

  copiar() {
    var copyText = document.getElementById("myUrl") as HTMLInputElement;

    copyText.select();
    copyText.setSelectionRange(0, 99999);

    document.execCommand("copy");

    alert("La URL ha sido copiada.");
  }
  enviarMail() {
    if (this.validateEmail(this.sendTo)) {
      this.http
        .post<any>(`${this.globals.url}/users/send_mail.php`, {
          email: this.sendTo,
          url: this.url,
        })
        .subscribe((data) => {
          console.log(data);
          if (data.success) {
            swal.fire({
              title: "Correo enviado",
              text: "",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "OK",
            });
          } else {
            swal.fire({
              title: "Error",
              text: data.message,
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "OK",
            });
          }
        });
    } else {
      swal.fire({
        title: "Por favor ingresa un correo válido.",
        text: "",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
    }
  }
  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  addPreguntaFinal() {
    this.submittedPreguntaFinal = true;
    // stop here if form is invalid
    if (this.preguntaFinalForm.invalid) {
      console.log("invalid");
      return;
    }
    this.preguntaFinal = this.preguntaFinalForm.value;
    this.preguntaFinal.modulo_id = this.moduloSeleccionado.id;
    console.log(this.preguntaFinal);
    this.http
      .post<any>(
        `${this.globals.url}/preguntas_final/add.php`,
        this.preguntaFinal
      )
      .subscribe((data) => {
        console.log(data);
        this.onResetPreguntaFinal();
        this.listarPreguntasFinal();
        $("#addPreguntaFinal").modal("hide");
      });
  }
  editPreguntaFinal() {
    this.submittedPreguntaFinal = true;
    console.log(this.preguntaFinalForm.value);
    // stop here if form is invalid
    if (this.preguntaFinalForm.invalid) {
      console.log("invalid");
      return;
    }
    const idd = this.preguntaFinal.id;
    const modulo_id = this.preguntaFinal.modulo_id;
    this.preguntaFinal = this.preguntaFinalForm.value;
    this.preguntaFinal.id = idd;
    this.preguntaFinal.modulo_id = modulo_id;
    this.http
      .post<any>(
        `${this.globals.url}/preguntas_final/edit.php`,
        this.preguntaFinal
      )
      .subscribe((data) => {
        console.log(data);
        this.onResetPreguntaFinal();
        this.listarPreguntasFinal();

        $("#editPreguntaFinal").modal("hide");
      });
  }
  deletePreguntaFinal(id) {
    $("#deletePreguntaFinal").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/preguntas_final/delete.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarPreguntasFinal();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  showAddPreguntaFinal() {
    this.onResetPreguntaFinal();
    this.preguntaFinalForm.controls["respuesta_correcta"].setValue(1);
    this.editing = 0;
    $("#addPreguntaFinal").modal("show");
  }
  showEditPreguntaFinal(id) {
    this.editing = 1;
    this.http
      .post<any>(`${this.globals.url}/preguntas_final/get.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        this.preguntaFinal = data;
        this.preguntaFinalForm.controls["pregunta"].setValue(
          this.preguntaFinal.pregunta
        );
        this.preguntaFinalForm.controls["respuesta1"].setValue(
          this.preguntaFinal.respuesta1
        );
        this.preguntaFinalForm.controls["respuesta2"].setValue(
          this.preguntaFinal.respuesta2
        );
        this.preguntaFinalForm.controls["respuesta3"].setValue(
          this.preguntaFinal.respuesta3
        );
        this.preguntaFinalForm.controls["respuesta4"].setValue(
          this.preguntaFinal.respuesta4
        );
        this.preguntaFinalForm.controls["respuesta_correcta"].setValue(
          this.preguntaFinal.respuesta_correcta
        );
      });

    $("#editPreguntaFinal").modal("show");
  }
  showDeletePreguntaFinal(a) {
    this.selectedItem = a;
    $("#deletePreguntaFinal").modal("show");
  }
  onResetPreguntaFinal() {
    this.preguntaFinal = {
      pregunta: "",
      respuesta1: "",
      respuesta2: "",
      respuesta3: "",
      respuesta4: "",
      respuesta_correcta: 1,
    };
    this.submittedPreguntaFinal = false;
    this.preguntaFinalForm.reset();
  }
  get fpf() {
    return this.preguntaFinalForm.controls;
  }

  addDefinicion() {
    this.submittedDefinicion = true;
    // stop here if form is invalid
    if (this.definicionForm.invalid) {
      console.log("invalid");
      return;
    }
    this.definicion = this.definicionForm.value;
    console.log(this.definicion);
    this.http
      .post<any>(`${this.globals.url}/definiciones/add.php`, this.definicion)
      .subscribe((data) => {
        console.log(data);
        this.onResetDefinicion();
        this.listarDefiniciones();
        $("#addDefinicion").modal("hide");
      });
  }
  editDefinicion() {
    this.submittedDefinicion = true;
    console.log(this.definicionForm.value);
    // stop here if form is invalid
    if (this.definicionForm.invalid) {
      console.log("invalid");
      return;
    }

    const idd = this.definicion.id;
    //const rutaa = this.modulo.ruta;
    this.definicion = this.definicionForm.value;
    this.definicion.id = idd;
    //if(this.definicionForm.value.ruta == "")this.definicionForm.value.ruta = rutaa;
    //if(this.file_name != "")this.definicion.ruta = this.file_name; else this.definicion.ruta = rutaa;
    this.http
      .post<any>(`${this.globals.url}/definiciones/edit.php`, this.definicion)
      .subscribe((data) => {
        console.log(data);
        this.onResetDefinicion();
        this.listarDefiniciones();

        $("#editDefinicion").modal("hide");
      });
  }
  deleteDefinicion(id) {
    $("#deleteDefinicion").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/definiciones/delete.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarDefiniciones();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  showAddDefinicion() {
    this.file_name = "";
    this.onResetDefinicion();
    this.editing = 0;
    $("#addDefinicion").modal("show");
  }
  showEditDefinicion(id) {
    this.editing = 1;
    this.http
      .post<any>(`${this.globals.url}/definiciones/get.php`, { id: id })
      .subscribe((data) => {
        console.log(data);
        this.definicion = data;
        //this.file_name = this.definicion.ruta;
        this.definicionForm.controls["nombre"].setValue(this.definicion.nombre);
        this.definicionForm.controls["definicion"].setValue(
          this.definicion.definicion
        );
      });

    $("#editDefinicion").modal("show");
  }
  showDeleteDefinicion(a) {
    this.selectedItem = a;
    $("#deleteDefinicion").modal("show");
  }
  onResetDefinicion() {
    this.definicion = {
      nombre: "",
      definicion: "#FFFFFF",
    };
    this.submittedDefinicion = false;
    this.definicionForm.reset();
  }
  get fd() {
    return this.definicionForm.controls;
  }

  addMeeting() {
    this.submittedMeeting = true;
    // stop here if form is invalid
    if (this.meetingForm.invalid) {
      console.log("invalid");
      return;
    }
    this.meeting = this.meetingForm.value;

    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone; // Determines the time zone of the browser client
    this.meeting.timezone = tz;
    console.log(this.meeting);
    this.http
      .post<any>(`${this.globals.url}/zoom/create_meeting.php`, this.meeting)
      .subscribe((data) => {
        console.log(data);
        this.onResetMeeting();
        this.listarMeetings();
        $("#addMeeting").modal("hide");
      });
  }

  deleteMeeting(id) {
    $("#deleteMeeting").modal("hide");
    this.http
      .post<any>(`${this.globals.url}/zoom/delete_meeting.php`, {
        meetingId: id,
      })
      .subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.listarMeetings();
        } else {
          $("#error").modal("show");
          this.message = data.message;
        }
      });
  }
  showAddMeeting() {
    this.file_name = "";
    this.onResetMeeting();
    this.editing = 0;
    $("#addMeeting").modal("show");
  }

  showDeleteMeeting(a) {
    this.selectedItem = a;
    $("#deleteMeeting").modal("show");
  }
  onResetMeeting() {
    this.meeting = {
      topic: "",
      start_time: "",
      duration: "",
      password: "",
      description: "",
    };
    this.submittedMeeting = false;
    this.meetingForm.reset();
  }
  get fmm() {
    return this.meetingForm.controls;
  }
}
