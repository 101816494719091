import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Ng2SearchPipe } from 'ng2-search-filter';
declare var $ : any;
import swal from 'sweetalert2';
import { FilterbymoduloPipe } from 'src/app/pipes/filterbymodulo.pipe';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
/* import * as imageMapResize from 'image-map-resizer';*/

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, AfterViewInit {
  instrucciones = "";
  lastHeight = 0;
  tiempo = "";
  porcentaje = 0;
  modulosCompletados = 0;
  modulos: any = [];
  modulos_temas: any = [];
  temas: any = [];
  contenidos: any = [];
  diapositivas: any = [];
  preguntas: any = [];
  moduloSeleccionado = "";
  nombreModulo = "";
  temaSeleccionado = "";
  nombreTema = "";
  contenido: any = {};
  diapositiva: any = {};
  loaderDiapositivas = true;
  section = 0;
  step = 0;
  currentContenido = 0;
  user: any = {};
  selectedItem: any = {};
  score = 0;
  coords = "217,84,289,154"
  @ViewChild('area', {static: false}) area: ElementRef;
  @ViewChild('dia', {static: false}) logo: ElementRef;

  mensajeEnviado = "";
  newPassword = "";
  newPasswordConfirm = "";
  submittedPassword = false;
  usuarios: any = [];
  definiciones: any = [];
  month_year = "";
  meetings: any = [];
  pct = 0;
  envivo = false;
  loaderMeetings = false;

  constructor(
    public globals: GlobalsService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute
    ) {
        this.globals.listen().subscribe((m:any) => {
          this.navSection(m);
          console.log(m);
      })
    }

    navSection(event) {
      this.changeTab(event);
    }

    ngAfterViewInit() {

    }
  ngOnInit() {

    this.pct = Math.PI*(90*2);
    window.scroll(0, 0);
    this.listarUsuarios();
    this.listarDefiniciones();
    this.getInstrucciones();
    this.initZoom();
    //localStorage.setItem("mensajeEnviado", "");
    this.moduloSeleccionado = localStorage.getItem("moduloSeleccionado");
    this.temaSeleccionado = localStorage.getItem("temaSeleccionado");
    var con = localStorage.getItem("currentContenido");

    if(this.route.snapshot.paramMap.get('modulo') != null) {
      this.moduloSeleccionado = this.route.snapshot.paramMap.get('modulo');
      localStorage.setItem("moduloSeleccionado", this.moduloSeleccionado);
    }
    if(this.route.snapshot.paramMap.get('tema') != null) {
      this.temaSeleccionado = this.route.snapshot.paramMap.get('tema');
      localStorage.setItem("temaSeleccionado", this.temaSeleccionado);
      con = null;

      this.router.navigateByUrl("/start");
    }

    if(this.moduloSeleccionado == null) {
      this.moduloSeleccionado = "";
      localStorage.setItem("moduloSeleccionado", "");
    }
    if(this.temaSeleccionado == null) {
      this.temaSeleccionado = "";
      localStorage.setItem("temaSeleccionado", "");
    }
    if(con == null) {
      this.currentContenido = 0;
      localStorage.setItem("currentContenido", "0");
      console.log(this.currentContenido);
    }

    console.log(this.temaSeleccionado);

    this.getUser();

  }
  unirse(m) {
    var url = m.join_url;
    window.open(url, "_blank");
  }
  initZoom() {
    var d = new Date();
    var n = d.getMonth();
    var y = d.getFullYear();
    var month= ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio",
            "Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
    this.month_year = month[n] + " " + y;

    this.loaderMeetings = true;
    this.http.get<any>(`${this.globals.url}/zoom/list_meetings.php`)
    .subscribe(data => {
      this.loaderMeetings = false;
      console.log(data);
      if(data.success) {
        console.log(data.response.meetings);
        this.meetings = data.response.meetings;

        var currentDate = new Date();



        for(var i = 0; i < this.meetings.length; i ++) {
          var tmpDate = new Date(this.meetings[i].start_time);

          this.meetings[i].date = this.format(tmpDate);
          this.meetings[i].time = this.tConvert(this.timeNow(tmpDate));

          var dateComp1 = new Date(this.meetings[i].start_time);
          var dateComp2 = new Date(dateComp1.getTime() + this.meetings[i].duration*60000);

          this.meetings[i].envivo = false;

          if(currentDate >= dateComp1 && currentDate <= dateComp2) {
            this.envivo = true;
            this.meetings[i].envivo = true;
          }
        }
      }
    });
  }
  tConvert(time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }

  timeNow(date) {
      var h = (date.getHours()<10?'0':'') + date.getHours();
      var m = (date.getMinutes()<10?'0':'') + date.getMinutes();
      return h + ':' + m;
  }
  format(date){
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
  }

  getInstrucciones() {
    this.http.get<any>(`${this.globals.url}/global/get_instrucciones.php`).subscribe(data => {
      this.instrucciones = data.instrucciones;
    });
  }
  verUsuario(u) {
    this.selectedItem = u;
    this.section = 6;
  }
  backTemas() {
    localStorage.setItem("moduloSeleccionado", "");
    this.section = 1;
  }
  listarModulos() {
    this.http.post<any>(`${this.globals.url}/modulos/list_user.php`, {usuario_id: this.user.id}).subscribe(data => {
      this.modulos = data;
      console.log(data);


      if(this.moduloSeleccionado != "") {
        let result = this.modulos.find(obj => obj.id == this.moduloSeleccionado);
        this.nombreModulo = result.nombre;

        if(result.unlocked == 0) {
          this.moduloSeleccionado = "";
          localStorage.setItem("moduloSeleccionado", "");

          swal.fire({
            title: 'No tienes acceso a este módulo.',
            text: "Ponte al corriente con el contenido de la plataforma para tener acceso a este módulo.",
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK'
          });
        }

      }


      var sum = 0;
      var minutos = 0;
      for(var i = 0; i < this.modulos.length; i ++) {
        sum += this.modulos[i].porcentaje;
        minutos += parseInt(this.modulos[i].tiempo);
      }

      this.tiempo = this.timeConvert(minutos);

      this.porcentaje = (sum / (this.modulos.length * 100)) * 100;

      this.updateCircle();
    });
  }
  updateCircle() {
    var $circle = $('#svg #bar');

    var r = $circle.attr('r');
    var c = Math.PI*(r*2);

    if (this.porcentaje < 0) { this.porcentaje = 0;}
    if (this.porcentaje > 100) { this.porcentaje = 100;}

    this.pct = ((100-this.porcentaje)/100)*c;

    $circle.css({ strokeDashoffset: this.pct});

    $('#cont').attr('data-pct',this.porcentaje);

  }
  listarModulos_insignia() {
    this.http.post<any>(`${this.globals.url}/modulos/list_insignia_user.php`, {usuario_id: this.user.id}).subscribe(data => {
      this.modulos_temas = data;
      console.log(data);

      this.modulosCompletados = 0;
      for(var i = 0; i < this.modulos_temas.length; i ++) {
        if(this.modulos_temas[i].porcentaje >= 100) this.modulosCompletados ++;
      }
    });
  }

  getPreguntas() {
    if(this.moduloSeleccionado != "") {
      let result = this.modulos.find(obj => obj.id == this.moduloSeleccionado);
      return result.preguntas;
    } else return 0;
  }

  getCompletado() {
    if(this.moduloSeleccionado != "") {
      let result = this.modulos.find(obj => obj.id == this.moduloSeleccionado);
      return result.completado;
    } else return 0;
  }
  timeConvert(minutos) {
    //console.log(minutos);
    var hours = (minutos / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    var hs = "";
    var ms = "";

    if(rhours != 1) {
      hs = "s";
    }
    if(rminutes != 1) {
      ms = "s";
    }

    if(rhours > 0) {
      if(rminutes > 0) {
        return rhours + " Hora"+ hs +" y " + rminutes + " minuto"+ ms +".";
      } else return rhours + " Hora"+ hs +"";
    } else {
      return rminutes + " minuto"+ ms +".";
    }


    }
  changePassword() {
    this.submittedPassword = true;

    if(this.newPassword.length < 6 || this.newPassword != this.newPasswordConfirm) {
      return;
    }

    this.http.post<any>(`${this.globals.url}/users/changePassword.php`, {id: this.user.id, password: this.newPassword}).subscribe(data => {
      console.log(data);
      if(data.success) {
        $('#changePassword').modal('hide');
        localStorage.setItem("mensajeEnviado", "1");
        swal.fire({
          title: '¡Felicidades!',
          text: "Tu contraseña ha sido cambiada.",
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'OK'
        });
      } else {
        swal.fire({
          title: 'Error',
          text: data.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK'
        });
      }
    });
  }
  listarUsuarios() {
    this.http.get<any>(`${this.globals.url}/users/list_resultados.php`).subscribe(data => {
      this.http
      .get<any>(`${this.globals.url_erp}/catalogos/sucursales/list.php`)
      .subscribe((x) => {
        var sucursalesList = x.response;

        data.forEach((element) => {
          let sucursal = sucursalesList.find(
            (x) => Number(x.id) === Number(element.sucursal_id)
          );

          if (sucursal) {
            element.nombre_sucursal = sucursal.nombre;
          }
        });

        this.usuarios = data;
      });
    });
  }
  listarDefiniciones() {
    this.http.get<any>(`${this.globals.url}/definiciones/list.php`).subscribe(data => {
      this.definiciones = data;
      console.log(data);
    });
  }
  listarModulosTemas() {
    this.http.post<any>(`${this.globals.url}/modulos/list_user.php`, {usuario_id: this.user.id}).subscribe(data => {
      this.modulos = data;
      console.log(data);


      if(this.moduloSeleccionado != "") {
        let result = this.modulos.find(obj => obj.id == this.moduloSeleccionado);
        this.nombreModulo = result.nombre;

        if(result.unlocked == 0) {
          this.moduloSeleccionado = "";
          localStorage.setItem("moduloSeleccionado", "");

          swal.fire({
            title: 'No tienes acceso a este módulo.',
            text: "Ponte al corriente con el contenido de la plataforma para tener acceso a este módulo.",
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK'
          });
        }

      }


      var sum = 0;
      var minutos = 0;
      for(var i = 0; i < this.modulos.length; i ++) {
        sum += this.modulos[i].porcentaje;
        minutos += parseInt(this.modulos[i].tiempo);
      }

      this.tiempo = this.timeConvert(minutos);

      this.porcentaje = (sum / (this.modulos.length * 100)) * 100;
      this.updateCircle();

      if(this.moduloSeleccionado != "") {
        this.listarTemas();
      }
    });
  }
  listarTemas() {
    console.log(this.temaSeleccionado);
    this.http.post<any>(`${this.globals.url}/temas/list_user.php`, {usuario_id: this.user.id, modulo_id: this.moduloSeleccionado}).subscribe(data => {
      this.temas = data;
      console.log(data);


      if(this.temaSeleccionado != "" && this.temaSeleccionado != "-1") {
        let result = this.temas.find(obj => obj.id == this.temaSeleccionado);

        if(result.unlocked == 0) {
          this.temaSeleccionado = "";
          localStorage.setItem("temaSeleccionado", "");

          swal.fire({
            title: 'No tienes acceso a este tema.',
            text: "Ponte al corriente con el contenido de la plataforma para tener acceso a este tema.",
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK'
          });
        }

      }

      if(this.temaSeleccionado == "-1") {
        for(var i = this.temas.length - 1; i >= 0; i --) {
          if(this.temas[i].completado == 0) {
            this.temaSeleccionado = "";
            localStorage.setItem("temaSeleccionado", "");
          }
        };
      }
      if(this.temaSeleccionado == "") {
        for(var i = this.temas.length - 1; i >= 0; i --) {
          if(this.temas[i].unlocked == 1) {
            this.temaSeleccionado = this.temas[i].id;
            localStorage.setItem("temaSeleccionado", this.temas[i].id);
            this.nombreTema = this.temas[i].nombre;
            break;
          }
        }
        // let result = this.modulos.find(obj => obj.id == this.moduloSeleccionado);
        // if(result.preguntas > 0) {
        //   let result = this.temas.find(obj => obj.id == this.temaSeleccionado);
        //   if(result.completado == 1) {
        //     this.temaSeleccionado = "-1";
        //     localStorage.setItem("temaSeleccionado", "-1");
        //   }
        // }

      }
      console.log(this.temaSeleccionado)
      this.section = 2;
      if(this.temaSeleccionado != "") {
        if(this.temaSeleccionado == "-1") this.openExamenFinal(); else
        this.openTema(this.temaSeleccionado, 0);
      }
    });
  }
  completarExamen() {
    console.log(this.preguntas);
    var ter = -1;
    for(var i = 0; i < this.preguntas.length; i ++) {
      if(this.preguntas[i].contestar == 0) {
        ter = i;
        break;
      }
    }
    console.log(ter);
    if(ter != -1) {
      swal.fire({
        title: 'Faltan preguntas por responder.',
        text: "",
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'OK'
      }).then((result) => {
        document.getElementById("pregunta" + this.preguntas[ter].id).scrollIntoView();
      });
    } else {
      var respuestas_malas = 0;
      for(var i = 0; i < this.preguntas.length; i ++) {
        if(this.preguntas[i].contestar != this.preguntas[i].respuesta_correcta) {
          respuestas_malas ++;
        }
      }
      if(respuestas_malas > 0) {
        if(respuestas_malas == 1) {
          swal.fire({
            title: 'Tiene 1 respuesta mala.',
            text: "Es necesario que estudies otra vez el contenido para volver a intentar el examen.",
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK'
          }).then((result) => {
            this.regresar();
          });
        } else {
          swal.fire({
            title: respuestas_malas + ' respuestas son incorrectas.',
            text: "Es necesario que estudies otra vez el contenido para volver a intentar el examen.",
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK'
          }).then((result) => {
            this.regresar();
          });
        }

      } else {
        swal.fire({
          title: '¡Felicitaciones!',
          text: "Has completado el examen con una puntuación perfecta, puedes continuar con el siguiente tema. Si este es el último tema, lo que sigue es un examen final.",
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'OK'
        }).then((result) => {
          this.siguienteTema();
        });
        //termino perfecto el examen
      }
    }

  }
  resetModulo() {
    this.http.post<any>(`${this.globals.url}/modulos/reset.php`, {id: this.user.id, modulo_id: this.moduloSeleccionado}).subscribe(data => {
      console.log(data);

      this.temaSeleccionado = "";
      localStorage.setItem("temaSeleccionado", "");
      this.currentContenido = 0;
      localStorage.setItem("currentContenido", "0");
      this.getUser();
    });
  }
  siguienteTema() {
    this.currentContenido = 0;
    localStorage.setItem("currentContenido", "0");
    console.log(this.temaSeleccionado);
    this.http.post<any>(`${this.globals.url}/temas/completar_tema.php`, {usuario_id: this.user.id, tema_id: this.temaSeleccionado}).subscribe(data => {
      console.log(data);
      for(var i = 0; i < this.temas.length; i ++) {
        if(this.temas[i].id == this.temaSeleccionado)this.temas[i].completado = 1;
      }
      if(data.success) {
        this.http.post<any>(`${this.globals.url}/temas/list_user.php`, {usuario_id: this.user.id, modulo_id: this.moduloSeleccionado}).subscribe(data => {



          var oldTema = this.temaSeleccionado;
          if(oldTema == this.temas[this.temas.length - 1].orden) {
            let result = this.modulos.find(obj => obj.id == this.moduloSeleccionado);
            if(result.preguntas > 0) {
              this.openExamenFinal();
            } else {
              this.score = 100;
              this.terminarModulo(0);
            }


          } else {
            //SELECT NEW TEMA
            console.log("MEEEEEEEEEEEEEEEEP")

            this.section = 2;
            this.temaSeleccionado = "";
            localStorage.setItem("temaSeleccionado", "");
            this.getUser();
          }

          this.step = 1;
        });
      } else {
        swal.fire({
          title: 'ERROR',
          text: data.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK'
        });
      }
    });
  }
  openExamenFinal() {
    this.preguntas = [];
    this.listarPreguntasFinal();
    this.contenido.completado = 0;
    this.step = 1000;
    this.contenido.tipo = 5;
    this.temaSeleccionado = "-1";
    localStorage.setItem("temaSeleccionado", "-1");
  }
  completarExamenFinal() {

    console.log(this.preguntas);
    var ter = -1;
    for(var i = 0; i < this.preguntas.length; i ++) {
      if(this.preguntas[i].contestar == 0) {
        ter = i;
        break;
      }
    }
    console.log(ter);
    if(ter != -1) {
      swal.fire({
        title: 'Faltan preguntas por responder.',
        text: "",
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'OK'
      }).then((result) => {
        document.getElementById("pregunta" + this.preguntas[ter].id).scrollIntoView();
      });
    } else {
      var respuestas_malas = 0;
      for(var i = 0; i < this.preguntas.length; i ++) {
        if(this.preguntas[i].contestar != this.preguntas[i].respuesta_correcta) {
          respuestas_malas ++;
        }
      }

      swal.fire({
        title: '¡Felicitaciones!',
        text: "Has completado el examen final, puedes continuar con el siguiente módulo.",
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK'
      }).then((result) => {
        this.score = ((this.preguntas.length - respuestas_malas) / this.preguntas.length) * 100;
        this.terminarModulo(1);
      });
      //termino perfecto el examen
      //if(respuestas_malas > 0) {
        // if(respuestas_malas == 1) {
        //   swal.fire({
        //     title: 'Tiene 1 respuesta mala.',
        //     text: "Por favor vuelve a contestar el examen para avanzar de módulo.",
        //     icon: 'error',
        //     showCancelButton: false,
        //     confirmButtonText: 'OK'
        //   }).then((result) => {
        //     this.regresarFinal();
        //   });
        // } else {
        //   swal.fire({
        //     title: 'Tiene ' + respuestas_malas + ' respuestas malas.',
        //     text: "Por favor vuelve a contestar el examen para avanzar de módulo.",
        //     icon: 'error',
        //     showCancelButton: false,
        //     confirmButtonText: 'OK'
        //   }).then((result) => {
        //     this.regresarFinal();
        //   });
        // }

      //} else {

      //}
    }



  }
  regresarFinal() {
    this.openExamenFinal();
  }
  terminarModulo(type) {
    this.http.post<any>(`${this.globals.url}/modulos/completar_modulo.php`, {usuario_id: this.user.id, modulo_id: this.moduloSeleccionado, score: this.score, type: type}).subscribe(data => {
      console.log(data);

      this.section = 1;
      localStorage.setItem("moduloSeleccionado", "");
      localStorage.setItem("temaSeleccionado", "");

      this.getUser();
    });
  }
  regresar() {
    this.currentContenido = 0;
    localStorage.setItem("currentContenido", "0");
    this.step = 1;

    this.http.post<any>(`${this.globals.url}/contenido/list.php`, {tema_id: this.temaSeleccionado}).subscribe(data => {
      console.log(this.temas);
      this.contenidos = data;
      let result = this.temas.find(obj => obj.id == this.temaSeleccionado);
      this.nombreTema = result.nombre;
      console.log(result);
      if(this.currentContenido < result.num) {
        this.contenido = this.contenidos[this.currentContenido];
        this.contenido.completado = 0;
        this.updateContenido();

        if(this.contenido.tipo == 1) {
          this.contenido.completado = 1;
        }
      } else {
        this.contenido.completado = 0;
        this.contenido.tipo = 4;
      }

    });
  }
  getTemas() {
    var filterbymoduloPipe = new FilterbymoduloPipe();
    return filterbymoduloPipe.transform(this.temas, this.moduloSeleccionado).length;
  }
  public changeTab(tab) {
    this.section = tab;

    var other = this;
    if(tab == 0) {
      setTimeout(function () {
        other.updateCircle();
      }, 10);
    }
    if(tab == 0) {
      localStorage.setItem("moduloSeleccionado", "");
      localStorage.setItem("temaSeleccionado", "");
      localStorage.setItem("currentContenido", "0");
    }
  }
  openModulo(m) {
    this.moduloSeleccionado = m;
    let result = this.modulos.find(obj => obj.id == this.moduloSeleccionado);
    this.nombreModulo = result.nombre;

    localStorage.setItem("moduloSeleccionado", m);

    this.temaSeleccionado = "";
    localStorage.setItem("temaSeleccionado", "");
    this.section = 2;

    this.listarTemas();
  }

  openTema(t, tipo) {
    this.currentContenido = 0;
    localStorage.setItem("currentContenido", "0");
    if(tipo == 1 && this.temaSeleccionado != "" && this.temaSeleccionado != t && this.currentContenido > 0) {
      swal.fire({
        title: 'ATENCIÓN',
        text: "Si cambias de tema perderás tu progreso, esta seguro que deseas continuar?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#292e4b;',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar'
      }).then((result) => {
        if (result.value) {


          console.log(t);
          this.temaSeleccionado = t;
          localStorage.setItem("temaSeleccionado", t);
          this.currentContenido = 0;
          localStorage.setItem("currentContenido", "0");

          this.contenido = {};
          this.http.post<any>(`${this.globals.url}/contenido/list.php`, {tema_id: this.temaSeleccionado}).subscribe(data => {
            console.log(this.temas);
            this.contenidos = data;
            let result = this.temas.find(obj => obj.id == this.temaSeleccionado);
            this.nombreTema = result.nombre;
            console.log(result);
            if(this.currentContenido < result.num) {
              this.contenido = this.contenidos[this.currentContenido];
              this.contenido.completado = 0;
              this.updateContenido();

              if(this.contenido.tipo == 1) {
                this.contenido.completado = 1;
              }
            } else {
              this.contenido.completado = 0;
              this.contenido.tipo = 4;
            }

          });
          this.listarPreguntas();
        }
      });
    } else {


      console.log(t);
      this.temaSeleccionado = t;
      localStorage.setItem("temaSeleccionado", t);

      this.contenido = {};
      this.http.post<any>(`${this.globals.url}/contenido/list.php`, {tema_id: this.temaSeleccionado}).subscribe(data => {
        console.log(this.temas);
        this.contenidos = data;
        let result = this.temas.find(obj => obj.id == this.temaSeleccionado);
        this.nombreTema = result.nombre;
        console.log(result);
        if(this.currentContenido < result.num) {
          this.contenido = this.contenidos[this.currentContenido];
          this.contenido.completado = 0;
          this.updateContenido();

          if(this.contenido.tipo == 1) {
            this.contenido.completado = 1;
          }
        } else {
          this.contenido.completado = 0;
          this.contenido.tipo = 4;
        }

      });
      this.listarPreguntas();
    }
  }
  responder(p, r) {
    if(p.contestar != r)p.contestar = r; else p.contestar = 0;
  }
  atras() {
    this.contenido = this.contenidos[this.currentContenido - 1];
    this.currentContenido --

    localStorage.setItem("currentContenido", this.currentContenido.toString());

    this.contenido.completado = 1;
    this.updateContenido();
  }
  siguiente() {

      let result = this.temas.find(obj => obj.id == this.temaSeleccionado);

      if(result.preguntas > 0) {
        if(this.currentContenido >= result.num - 1) {
          swal.fire({
            title: '¡Felicitaciones!',
            text: "Ha terminado el contenido del tema, haz click en el botón de abajo para continuar con el examen.",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3e4c66',
            confirmButtonText: 'Continuar con el examen'
          }).then((result) => {
            this.step = 1000;
            this.contenido.tipo = 4;
            this.contenido.completado = 0;
          });
        } else {
          this.contenido = this.contenidos[this.currentContenido + 1];
          this.currentContenido ++;

          localStorage.setItem("currentContenido", this.currentContenido.toString());

          this.contenido.completado = 0;
          this.updateContenido();
        }
      } else {
        if(this.currentContenido >= result.num - 1) {
          swal.fire({
            title: '¡Felicitaciones!',
            text: "Ha terminado el contenido del tema, haz click en el botón de abajo para continuar.",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3e4c66',
            confirmButtonText: 'Continuar'
          }).then((result) => {
            this.siguienteTema();
          });
        } else {
          this.contenido = this.contenidos[this.currentContenido + 1];
          this.currentContenido ++;

          localStorage.setItem("currentContenido", this.currentContenido.toString());

          this.contenido.completado = 0;
          this.updateContenido();
        }
      }

  }
  listarPreguntas() {
    this.preguntas = [];
    this.http.post<any>(`${this.globals.url}/preguntas/list.php`, {tema_id: this.temaSeleccionado}).subscribe(data => {
      console.log(data);
      this.preguntas = data;
      for(var i = 0; i < this.preguntas.length; i ++) {
        this.preguntas[i].contestar = 0;
      }
    });
  }
  listarPreguntasFinal() {
    this.http.post<any>(`${this.globals.url}/preguntas_final/list.php`, {modulo_id: this.moduloSeleccionado}).subscribe(data => {
      console.log(data);
      this.preguntas = data;
      for(var i = 0; i < this.preguntas.length; i ++) {
        this.preguntas[i].contestar = 0;
      }
    });
  }
  updateContenido() {
    var vids = $("video");
    $.each(vids, function(){
          this.controls = false;
    });
    /*
    if(this.contenido.tipo == 3) {
      $("#video").bind("ended", function() {
        alert('Video ended!');
    });

      let audioPlayer = <HTMLMediaElement>document.getElementById("video");
      audioPlayer.onended = function() {
        console.log("The audio has ended");
      };

      document.getElementById('video').addEventListener('ended',myHandler,false);
      function myHandler(e) {
        console.log("The audio has ended");
      }
    }
    */
   if(this.contenido.tipo == 1) {
     this.contenido.completado = 1;
   }


  }
  openExamenFinalMenu() {
    var canOpen = true;
    for(var i = 0; i < this.temas.length; i ++) {
      if(this.temas[i].completado == 0)
      {
        canOpen = false;

        swal.fire({
          title: 'Aún no haz completado los submódulos',
          text: "Para tomar el examen final es necesario finalizar correctamente todos los temas del módulo.",
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3e4c66',
          confirmButtonText: 'OK'
        });

        break;
      }
    }
    if(canOpen) {
      this.openExamenFinal();
    }

  }
  videoEnd() {
    this.contenido.completado = 1;
  }
  skip() {
    this.step = this.diapositivas.length;
    this.contenido.completado = 1;
  }
  next() {
    this.step ++;

    if(this.step >= this.diapositivas.length) {
      this.contenido.completado = 1;
    } else {
      this.diapositiva = this.diapositivas[this.step];

      this.loaderDiapositivas = true;
      var other = this;
      setTimeout(function () {
        other.resetLoader();
      }, 200);
    }
  }
  resetLoader() {
    this.loaderDiapositivas = false;
    setTimeout(function () {
      imageMapResize();
    }, 50);
  }
  getHeight() {
    this.lastHeight = (this.logo.nativeElement as HTMLImageElement).height;
  }
  dismissPractica() {
    $('#practica').modal('hide');
  }
  empezar() {
    $('#practica').modal('show');

    this.step = 0;
    this.contenido.completado = 0;
    this.loaderDiapositivas = true;
    this.http.post<any>(`${this.globals.url}/diapositivas/list.php`, {presentacion_id: this.contenido.texto}).subscribe(data => {
      console.log(data);
      this.diapositivas = data;

      this.loaderDiapositivas = false;
      this.diapositiva = this.diapositivas[this.step];


      this.loaderDiapositivas = true;
      var other = this;
      setTimeout(function () {
        other.resetLoader();
      }, 200);

    });
  }

  verDefinicion(d) {
    //$('#definicion').modal('show');
    this.section = 7;
    this.selectedItem = d;
  }

  getUser() {
    this.http.get<any>(`${this.globals.url}/users/get_current.php`)
    .subscribe(data => {
    this.user = data;
    console.log(this.user);

    if(data.success) {
      if(data.setpassword == 0) {
        this.mensajeEnviado = localStorage.getItem("mensajeEnviado");
        if(this.mensajeEnviado != "1") {
          swal.fire({
            title: 'Aviso Importante',
            text: "Tu contraseña no ha sido cambiada, desea cambiarla en este momento?",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No gracias',
            cancelButtonColor: '#d33',
            confirmButtonColor: '#3e4c66',
            confirmButtonText: 'Cambiar',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
          }).then((result) => {
            if(result.value) {
              this.newPassword = "";
              $('#changePassword').modal('show');
            } else {
              localStorage.setItem("mensajeEnviado", "1");
            }
          });
        }
      }

      this.moduloSeleccionado = localStorage.getItem("moduloSeleccionado");
      this.temaSeleccionado = localStorage.getItem("temaSeleccionado");
      this.currentContenido = parseInt(localStorage.getItem("currentContenido"));
      this.listarModulosTemas();
      this.listarModulos_insignia();
    }

  });
  }

  playVideo() {
    //var myVideo = document.getElementById("myVid");
    let audioPlayer = <HTMLMediaElement>document.getElementById("video");
    audioPlayer.play();
    audioPlayer.removeAttribute("controls");
  }
  pauseVideo() {
    let audioPlayer = <HTMLMediaElement>document.getElementById("video");
    audioPlayer.pause();
    audioPlayer.removeAttribute("controls");
  }
  zoom() {
    this.http.get<any>(`${this.globals.url}/zoom/login.php`)
    .subscribe(data => {
      console.log(data);
    });
  }
}
