import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from './cliente/start/start.component';
import { PanelComponent } from './admin/panel/panel.component';
import { LoginComponent } from './cliente/login/login.component';
import { RegistroComponent } from './cliente/registro/registro.component';
import { RedirectComponent } from './cliente/redirect/redirect.component';
import { MenuComponent } from './cliente/menu/menu.component';

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "dashboard", component: PanelComponent },
  { path: "start", component: StartComponent },
  { path: "menu", component: MenuComponent },
  { path: "redirect/:modulo/:tema", component: RedirectComponent },
  { path: "**", redirectTo: "/start" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
