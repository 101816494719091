import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  constructor(
    public router: Router,
    public globals: GlobalsService,
    public http: HttpClient) { }

  ngOnInit() {

  }
  logout() {
    this.http.get<any>(`${this.globals.url}/global/destruirSesion.php`).subscribe(data => {
      this.router.navigateByUrl("/menu");
      this.globals.logged = 0;
  });
  }
  changeTab(tab) {
    this.globals.changeTab(tab);
  }

}
