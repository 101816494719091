import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterbymodulo'
})
export class FilterbymoduloPipe implements PipeTransform {

  transform(items: any[], modulo_id: any): any {
    return items.filter(item => item.modulo_id === modulo_id);
  }

}
