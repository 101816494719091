import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(
    public globals: GlobalsService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
  }

}
