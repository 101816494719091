import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalsService } from './services/globals.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'erus-certificaciones';
  constructor(public router: Router, private http: HttpClient, public globals: GlobalsService, private location: Location) {}

  ngOnInit() {
    if(this.location.path().includes('/admin')) {
      this.http.get<any>(`${this.globals.url}/global/validarSesion.php`).subscribe(data => {
        console.log(data);
        this.globals.nombre = data.nombre;
        if(data.type == 0) {
          console.log("valid login");
          this.globals.logged = 1;
          this.globals.type = data.type;
          this.globals.setpassword = data.setpassword;
        }
        else
        {
          if(data.type == 1) {
            this.globals.logged = 1;
            this.globals.type = data.type;
            this.globals.setpassword = data.setpassword;
          }
          console.log("invalid login");
          this.router.navigateByUrl("/menu");
        }
    });
  } else {
    this.http.get<any>(`${this.globals.url}/global/validarSesion.php`).subscribe(data => {
      console.log(data);
      if(data.type != 2) {

        this.globals.logged = 1;
        this.globals.nombre = data.nombre;
        this.globals.type = data.type;
        this.globals.setpassword = data.setpassword;
      } else {
        console.log("invalid login");
        this.router.navigateByUrl("/menu");
      }
  });
  }
  }
}
