import { Component, OnInit, Input } from '@angular/core';
/*import * as imageMapResize from 'image-map-resizer';*/
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-practica',
  templateUrl: './practica.component.html',
  styleUrls: ['./practica.component.scss']
})
export class PracticaComponent implements OnInit {

  @Input() practica: string;
  @Input() step: number;
  meep = 0;
  constructor(
    public globals: GlobalsService
    ) { }

  ngOnInit() {
    imageMapResize();
  }
  next() {
    this.meep = 1;
  }

}
