import { Component, OnInit } from '@angular/core';
import { GlobalsService } from 'src/app/services/globals.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public globals: GlobalsService) { }

  ngOnInit() {
  }

}
