import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './cliente/start/start.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RegistroComponent } from './cliente/registro/registro.component';
import { LoginComponent } from './cliente/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { PanelComponent } from './admin/panel/panel.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ColorPickerModule } from 'ngx-color-picker';
import { FilterbymoduloPipe } from './pipes/filterbymodulo.pipe';
import { PracticaComponent } from './cliente/practica/practica.component';
import { RedirectComponent } from './cliente/redirect/redirect.component';
import { NavbarLoginComponent } from './components/navbar-login/navbar-login.component';
import { MenuComponent } from './cliente/menu/menu.component';
import { NavbarMenuComponent } from './components/navbar-menu/navbar-menu.component';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    NavbarComponent,
    RegistroComponent,
    LoginComponent,
    FooterComponent,
    PanelComponent,
    FilterbymoduloPipe,
    PracticaComponent,
    RedirectComponent,
    NavbarLoginComponent,
    MenuComponent,
    NavbarMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Ng2SearchPipeModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ColorPickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
